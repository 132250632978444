@import "../../../../styles/variables.scss";

.search {
    width: 450px;
    height: 50px;
    position: relative;
    background-color: $neutral-100;
    border: .5px solid $neutral-700;
    border-radius: 5px;
    cursor: pointer;
    @include rowflexx(center);

    @include breakpoint(mobileonly){
        width: 80vw !important;
    }
    @include breakpoint(desktop){
        width: 100%;
    }
    &__box {
        height: 100%;
        @include rowflexx(center);
    }
    &__inputs {
        width: 100%;
        height: 100%;
        position: relative;
        img{
            position: absolute;
            top: 20px;
            bottom: 0px;
            left: 20px;
            opacity: .5;
            transition: all .5s ease;
        }
        input:focus ~ img,
        input:not(:placeholder-shown) ~ img{
            opacity: 1;
        }
        input {
            padding-left: 60px;
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: none;
            margin: none;
            outline: none;
            cursor: pointer;
            @include fontNormal(1em,1em, $neutral-500);
            &:focus {
                @include fontNormal(1em,1em, $main-900);
            }
        }
        input[name="eventname"]{
            border-right: 1px solid $neutral-500;
        }
    }
    input.search__input {
        padding-left: 20px;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        margin: none;
        outline: none;
        cursor: pointer;
        @include fontNormal(1em,1em, $neutral-500);
        transition: all .5s ease;

        @include breakpoint(desktop){
            padding-left: 20px;
        }
        &:focus {
            @include fontNormal(1em,1em, $main-900);
        }
    }
    &__btn{
        width: 150px;
        position: absolute;
        top: 6.5px;
        bottom: 0px;
        right: 10px;
        @include breakpoint(mobileonly){
            top: -.9em;
        }
        button{
            height: 36px;
        }
    }

    &.--subscribe {
        width: 550px;
        *{
            button {
                background-color: $yellow-500 !important;
                color: $yellow-901;
            }
        }
    }
}