@import "../../styles/variables.scss";

.countdown {
    width: 100%;
    height: 100vh;
    background-color: $main-100;
    position: relative;
    // background-image: url('https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&cs=tinysrgb&w=1600');
    // background-image: url(../../assets/svgs/afropattern.svg);
    // background-repeat: no-repeat;
    // background-size: cover;
    @include rowflex(center,space-between);

    &__blurOne {
        position: absolute;
        height: 125.98px;
        left: 23.47%;
        right: 66.67%;
        // top: 131.84px;
        top:0px;
        background: #4C5FF7;
        filter: blur(150px);
    }

    &__blurTwo{
        position: absolute;
        height: 162.27px;
        width: 100px;
        right: 21.66%;
        bottom: 0px;
        background: #5452A3;
        filter: blur(150px);
        transform: matrix(0.95, -0.32, 0.32, 0.95, 0, 0);
    }
    &__inner {
        @include colflexx(center);
        gap: 50px;
    }

    &__left{
        height: 100%;
        background-color: $main-902;
        padding: 70px;
        
        @include breakpoint(desktop){
            // width: 570px
            width: 30vw;
        }
        @include colflexx(flex-start);
        gap: 10px;
        

        h6{
            text-transform: uppercase;
            letter-spacing: .1em;
            @include fontBold(1em,1em,$main-400);
        }
        h4 {
            @include fontSemiBold(3em,1.2em, #fff);
            strong{
                color: $main-500;
                font-weight: 600;
            }
        }
        p{
            @include fontNormal(1.2em,1.4em, #fff)
        }
    }
    .--heroTwo{
        *{
            p {
                color: #fff !important;
            }
            

        }
    }
    &__right {
        background-color: tomato;
        width: 60vw;
        height: 100%;
        background-image: url('https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&cs=tinysrgb&w=1600');
    background-repeat: no-repeat;
    background-size: cover;
        
    }


    &__powered {
        position: absolute;
        bottom: 50px;
        margin: 0 auto;
        @include fontNormal(1em,1em,#fff);
    }
}

.baltics{
    background-color: #FFF6CA;
    height: fit-content;
    max-width: 1900px;

    &__cont{
        padding: 100px 120px;
        overflow: hidden;
        @include rowflex(center,center);

    }
    &__image{
        width: 70vw;

        @include breakpoint(desktop){
            width: 1200px;
            height: 700px;
        }
    }
}