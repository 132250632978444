@import "../../styles/variables.scss";

.hero {
    position: relative;
    height: 90vh;
    overflow-x: hidden;
    padding: 100px 120px 0px 120px;
    @include rowflex(center,center);
    &__inner {
        z-index: 1;
        margin-top: -50px;
        height: fit-content;
        @include breakpoint(desktop){
            margin-top: -250px;
        }
    }

    &__oval {
        position: absolute;
        top: -5px;
        z-index: 0;
        height: 65px;
    }
    &__flag{
        width: 100%;
        width: auto;
        position: absolute;
        top: 0px;
        z-index: 10;
    }
    
    &__top {
        @include colflexx(center);
        gap: 30px;

        @include breakpoint(desktop){
            gap: 30px;
        }
    }
    &__bkg, &__people {
        z-index: 0;
        position: absolute;
        width: 100%;
        bottom: 0px;

        @include breakpoint(mobileonly){
            width: 150vw;
        }
        @include breakpoint(phablet){
            width: 150vw;
        }

    }
    &__people{
        bottom: 0px;
        width: 90vw;

        @include breakpoint(desktop){
            width: 50vw;
        }
    }

    &__blogo{
        width: 250px;

        @include breakpoint(desktop){
            // width: auto;
            width: 300px;
        }
        @include breakpoint(mobileonly){
            width: 250px;
        }
    }
    &__innertopp{
        position: relative;
        @include colflexx(center);
        gap: 20px;
        p{
            text-align: center;
            font-size: large;
            font-weight: 600;
            color: $yellow-900;
        }
        h4 {
            text-transform: uppercase;
            &.herob__subject {
                z-index: 1;
                letter-spacing: .1em;
                @include fontBlack(1em,1em,#fff);
                margin-bottom: 30px;
            }
            &.herob__subscribe {
                font-weight: 900;
                text-align: center;
                font-size: large;
                color: $yellow-900;
            }
        }
    }
    &__innerbottom{
        @include colflexx(center);
        gap: 5px;
        p{
            text-align: center;
            font-size: large;
            font-weight: 600;
            color: $yellow-900;
            @include breakpoint(mobileonly){
                width: 80vw;
                font-size: 1.2em;
            }
            @include breakpoint(phablet){
                width: 70vw;
            }
            @include breakpoint(desktop){
                width: 450px;
            }
        }
    }
}