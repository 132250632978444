@import "../../../styles/variables.scss";


.platform {
    width: 100%;
    cursor: pointer;
    @include colflexx(flex-start);
    min-width: 20px;
    width: fit-content;
    padding: 0px 30px 0px 30px;
    outline: none; 
    border: none;
    height: 36px;
    border-radius: 5px !important;
    &.platform_mainbtn {
        width: 100%;
        background-color:  $orange-500;
        transition: all .5s ease;
        font-size: 1.2em;
        white-space: nowrap;
        @include rowflex(center,center);
        @include fontSemiBold(1.1em, 1em, #fff);
        i {
            margin-right: 10px;
        }
        &.platform_mainbtn__size-small{
            height: 40px;
        }
        &.platform_mainbtn__bkg-dark{
            background-color: $main-900;
        }
        &.platform_mainbtn__bkg-white{
            background-color: #fff;
            color: $main-900;
            box-shadow: 0px 4px 20px rgba(224, 224, 224, 0.2);
        }
        &:hover {
            color: #fff;
            background-color: $main-902;
        }
    
        &:disabled{
            background-color: $grey-500;
            color: #fff;
            cursor: not-allowed;
        }
        
        @include breakpoint(mobileonly){
            width: 100%;
            margin-top: 20px;
        }
    }

    &.platform_mainbtnicon {
        width: 100%;
        background-color:  $orange-500;
        transition: all .5s ease;
        font-size: 1.2em;
        white-space: nowrap;
        @include rowflex(center,center);
        @include fontSemiBold(1.1em, 1em, #fff);
        i {
            margin-right: 10px;
        }
        &.platform_mainbtnicon__size-small{
            height: 40px;
        }
        &.platform_mainbtnicon__bkg-dark{
            background-color: $main-900;
        }
        &.platform_mainbtnicon__bkg-white{
            background-color: #fff;
            color: $main-900;
            box-shadow: 0px 4px 20px rgba(224, 224, 224, 0.2);
        }
        &:hover {
            color: #fff;
            background-color: $main-902;
        }
    
        &:disabled{
            background-color: $grey-500;
            color: #fff;
            cursor: not-allowed;
        }
        
        @include breakpoint(mobileonly){
            width: 100%;
            margin-top: 20px;
        }
    }

    &.platform_secbtn {
        width: 100%;
        background-color:  transparent;
        // border: 1px solid $main-900;
        transition: all .5s ease;
        font-size: 1.2em;
        white-space: nowrap;
        @include rowflex(center,center);
        @include fontSemiBold(1.1em, 1em, $main-900);

        &:hover {
            color: $main-900;
            background-color: $main-100;
        }

        &.--red {
           background-color: rgb(255, 235, 235);
            @include fontSemiBold(1.1em, 1em, red);

            &:hover {
                color:red;
                background-color:  rgb(255, 219, 219);
            }
        }
        &.--yellow {
            background-color: $orange-100;
             @include fontSemiBold(1.1em, 1em, $orange-600);
 
            //  &:hover {
            //      color:red;
            //      background-color:  rgb(255, 219, 219);
            //  }
         }

        

        &:disabled{
            background-color: $grey-500;
            color: #fff;
            cursor: not-allowed;
        }
    }
    &.platform_link {
        width: 100%;
        background-color:  transparent;
        height: 15px;
        padding: 0px;
        transition: all .5s ease;
        white-space: nowrap;
        @include rowflex(center,center); 
        @include fontMedium(1.1em, 1em, $grey-500);
        
        &.platform_link__size-small{
            width: fit-content;
        }
        &:hover {
            color: $grey-800;
        }

        &.--red {
        //    background-color: rgb(255, 235, 235);
            @include fontMedium(1.1em, 1em, red);

            &:hover {
                color:red;
                // background-color:  rgb(255, 219, 219);
            }
        }

        &.--grey {
            @include fontMedium(1.1em, 1em, $grey-500);
 
            &:hover {
                color: $grey-800;
            }
         }

         &.--dark {
            @include fontMedium(1.1em, 1em, $main-900);
 
            &:hover {
                color: $grey-800;
            }
         }
        

        &:disabled{
            background-color: $grey-500;
            color: #fff;
            cursor: not-allowed;
        }
    }
    &.platform_darklink {
        // width: 100%;
        background-color:  transparent !important;
        height: 20px;
        padding: 0px;
        transition: all .5s ease;
        white-space: nowrap;
        @include rowflex(center,center);
        @include fontMedium(1.1em, 1em, $main-900);
        color:  $main-900 !important;
        &.platform_link__size-small{
            width: fit-content;
            height: 20px;
        }
        &:hover {
            color: $grey-800;
        }

        &:disabled{
            background-color: $grey-500;
            color: #fff;
            cursor: not-allowed;
        }
    }
    &.platform_slantlink {
        width: fit-content;
        background-color:  transparent !important;
        height: fit-content;
        padding: 0px;
        transition: all .5s ease;
        white-space: nowrap;
        font-style: italic !important;
        @include rowflex(center,center);
        @include fontMedium(1.1em, 1em, $main-900);
        
        &.--red {
                @include fontMedium(1.1em, 1em, red);
    
                &:hover {
                    color:red;
                }
            }

            
        &.platform_link__size-small{
            width: fit-content;
            height: 20px;
        }
        &:hover {
            color: $grey-800;
        }

        &:disabled{
            background-color: $grey-500;
            color: #fff;
            cursor: not-allowed;
        }
    }
    &.--child{
        width: 100%;
        margin: 0;
        margin-top: 20px;
        @include fontNormal(.9em, 1.2em, $text-main);
    }

    strong {
        color: $orange-500;
    }
}

.back {
    cursor: pointer;
    width: fit-content;
    
    @include rowflexx(center);
    p{
        margin: 0;
        transition: all .5s ease;
        @include fontMedium(1.2em, 1em, $grey-800);
    }

    i {
        font-size: 1.2em;
        margin-right: 10px;
        color: $grey-800;
        transition: all .5s ease;
    }

    &:hover {

        p, i {
            color:$main-800;
        }
    }
}

.arrowlink {
    cursor: pointer;
    width: fit-content;
    @include rowflexx(center);
    margin: 0;
    transition: all .5s ease;
    @include fontMedium(1em, 1em, $main-900);

    i {
        font-size: 1.2em;
        margin-left: 10px;
        color: $main-900;
        transition: all .5s ease;
    }

    &:hover {

        p, i {
            color:$main-800;
        }
    }
}

.iconlink {
    cursor: pointer;
    width: fit-content;
    @include rowflexx(center);
    margin: 0;
    transition: all .5s ease;
    @include fontMedium(1em, 1em, $main-900);

    i {
        font-size: 1.2em;
        margin-right: 10px;
        color: $main-900;
        transition: all .5s ease;
    }

    &:hover {

        p, i {
            color:$main-900;
        }
    }
}